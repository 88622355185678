import { createSlice } from '@reduxjs/toolkit'

export const counterSlice = createSlice({
  name: 'nowplaying',
  initialState: {
    card: {
      title: "",
      uuid: "",
      image: "",
      ext_file: "",
      thumbnail: "",
      song: {
        ext_file: "",
        file: "",
      },
      price: 2,
      id: 0,

    },
  },
  reducers: {
    urlSetter: (state, action) => {
      state.card = action.payload
    }
  }
})

// Action creators are generated for each case reducer function
export const { urlSetter } = counterSlice.actions

export default counterSlice.reducer