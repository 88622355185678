// import React from "react";
import { FiShare } from "react-icons/fi";
import { WhatsappShareButton } from "react-share";

interface ShareProps {
  uuid: string;
}

function ShareButton({ uuid }: ShareProps) {
  const domainUrl = "https://beta.songcards.io";
  const shareUrl = `${domainUrl}/cards/${uuid}`;
  const message = "Saw this and thought of you :-)";
  // console.log("share url is...", shareUrl)

  return (
    // <Button colorScheme="green" mt={10}>
    <WhatsappShareButton url={shareUrl} title={message}>
      {/* <AiOutlineWhatsApp size={30} /> */}
      <FiShare />
    </WhatsappShareButton>
    // </Button>
  );
}

export default ShareButton;
