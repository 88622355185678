import { Box, Center } from "@chakra-ui/react";
import Image from "next/image";

const Thumbnail = (props: {
  ext_file: string;
  url: string;
  thumbnail: string;
  comp_file?: string;
}) => {
  // const [isLoading, setIsLoading] = useState(true);
  // const [skeleton, setSkeleton] = useState(true);
  // const [isLoaded, setIsLoaded] = useState(false);

  return (
    <>
      <Center>
        {props.ext_file && !props.comp_file && (
          <Box
            borderRadius="md"
            maxWidth={350}
            minWidth={260}
            minHeight={260}
            overflow="hidden"
          >
            <Image
              src={props.ext_file}
              alt="Thumbnail"
              height={380}
              width={380}
              // placeholder='blur'
              // blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNsk+z/DwAEigIvqEbIswAAAABJRU5ErkJggg=="
              // onLoad={() => setIsLoaded(true)}
            />
          </Box>
        )}
        {props.comp_file && (
          <Box
            borderRadius="md"
            maxWidth={350}
            minWidth={260}
            minHeight={260}
            overflow="hidden"
          >
            <Image
              src={props.comp_file}
              alt="Thumbnail"
              height={380}
              width={380}
              // placeholder='blur'
              // blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNsk+z/DwAEigIvqEbIswAAAABJRU5ErkJggg=="
              // onLoad={() => setIsLoaded(true)}
            />
          </Box>
        )}
        {props.url && !props.comp_file && (
          <Box
            borderRadius="md"
            maxWidth={350}
            minWidth={260}
            minHeight={260}
            overflow="hidden"
          >
            <Image
              src={props.url}
              alt="Thumbnail"
              height={380}
              width={380}
              // placeholder='blur'
              // blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNsk+z/DwAEigIvqEbIswAAAABJRU5ErkJggg=="
              // onLoad={() => setIsLoaded(true)}
            />
          </Box>
        )}
      </Center>
    </>
  );
};

export default Thumbnail;
