import { MoonIcon, SunIcon } from "@chakra-ui/icons";
import {
  Box,
  Container,
  Divider,
  Flex,
  Spacer,
  useColorMode
} from "@chakra-ui/react";
import Image from "next/image";
import Link from "next/link";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { CiTrophy } from "react-icons/ci";
import { RiAccountCircleLine } from "react-icons/ri";

const DiscoverIcon = () => {
  const { colorMode, toggleColorMode } = useColorMode();

  return (
    <>
      <Container maxW={650} mt={5}>
        <Flex>
          <Box
            onClick={toggleColorMode}
            mb={0}
            p="4"
            ml={0}
            display="flex"
            alignItems="center"
            cursor="pointer"
          >
            {colorMode === "light" ? <MoonIcon /> : <SunIcon />}
          </Box>
          <Spacer />
          <Box p="4" ml={0} display="flex" alignItems="center" height={70}>
            <Link href={`/`}>
              {colorMode === "dark" && (
                <Image
                  src="/SC Icon_B_White_m-res.png"
                  width={25}
                  height={100}
                  alt="Discover more Songcards"
                />
              )}
              {colorMode === "light" && (
                <Image
                  src="/SC Icon_B_Black_m-res.png"
                  width={25}
                  height={100}
                  alt="Discover more Songcards"
                />
              )}
            </Link>
          </Box>
          <Spacer />
          <Box p="4" mt={2}>
            <Link href={`/account`}>
              <RiAccountCircleLine size={20} />
            </Link>
          </Box>
        </Flex>
        <Divider mt={5} mb={10} />
      </Container>
    </>
  );
};

export default DiscoverIcon;
