import { extendTheme } from "@chakra-ui/react";
// import "@fontsource/raleway"; // Defaults to weight 400
// import "@fontsource/raleway/400.css"; // Specify weight
// import "@fontsource/raleway/400-italic.css"; // Specify weight and style
// import "@fontsource/open-sans"; // Defaults to weight 400
// import "@fontsource/open-sans/700.css"; // Specify weight
// import "@fontsource/open-sans/400-italic.css"; // Specify weight and style
// import "@fontsource/source-sans-pro"; // Defaults to weight 400
// import "@fontsource/source-sans-pro/400.css"; // Specify weight
// import "@fontsource/source-sans-pro/400-italic.css"; // Specify weight and style

const theme = extendTheme({
  config: {
    initialColorMode: "system",
    useSystemColorMode: true,
  },
  fonts: {
    heading: `'Open Sans', sans-serif`,
    body: `'Raleway', sans-serif`,
  },
  colors: {
    dark: {
      900: "black", // set the background color for dark mode
      100: "#151515",
      300: "121212",
    },
    light: {
      100: "white",
    },
  },
  styles: {
    global: (props: { colorMode: string }) => ({
      body: {
        bg: props.colorMode === "dark" ? "dark.900" : "light.100", // use the custom background color for dark mode
        bgGradient: props.colorMode === "dark" ? "black" : "light.100",
        minHeight: 1000,
      },
    }),
  },
});

export default theme;
